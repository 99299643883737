@tailwind base;
@tailwind components;
@tailwind utilities;




body, html {
  overflow-x: hidden;
  scroll-behavior: smooth;
  background-color: black;
}