@font-face {
  font-family: 'Bioweapon';
  src: url('../../assets/BIOWEAPON.ttf');
  format: 'opentype';
}

.upload-h2 {
  font-family: 'Bioweapon';
  font-size: 22px;
  text-align: center;
  margin-top: 50px;
}

.upload-bg {
  position: relative;
  width: 100%;
  height: 100%;

  background: #000000;
  --gap: 5em;
  --line: 1px;
  --color: rgba(255, 255, 255, 0.2);

  background-image: linear-gradient(
      -90deg,
      transparent calc(var(--gap) - var(--line)),
      var(--color) calc(var(--gap) - var(--line) + 1px),
      var(--color) var(--gap)
    ),
    linear-gradient(
      0deg,
      transparent calc(var(--gap) - var(--line)),
      var(--color) calc(var(--gap) - var(--line) + 1px),
      var(--color) var(--gap)
    );
  background-size: var(--gap) var(--gap);
}

.upload-btn {
  color: white;
  position: absolute;
  padding: 9px 18px;
  border-radius: 7px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 2px;
  overflow: hidden;
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.upload-btn:hover {
  background: #5d1ba8;
  color: #ffffff;
  box-shadow: 0 0 30px 5px rgba(99, 1, 211, 0.815);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.upload-btn:hover::before {
  -webkit-animation: sh02 0.5s 0s linear;
  -moz-animation: sh02 0.5s 0s linear;
  animation: sh02 0.5s 0s linear;
}

.upload-btn::before {
  content: '';
  display: block;
  width: 0px;
  height: 86%;
  position: absolute;
  top: 7%;
  left: 0%;
  opacity: 0;
  background: #fff;
  box-shadow: 0 0 50px 30px #fff;
  -webkit-transform: skewX(-20deg);
  -moz-transform: skewX(-20deg);
  -ms-transform: skewX(-20deg);
  -o-transform: skewX(-20deg);
  transform: skewX(-20deg);
}

@keyframes sh02 {
  from {
    opacity: 0;
    left: 0%;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
    left: 100%;
  }
}

.upload-btn:active {
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
}

.upload-input-box {
  max-width: 250px;
  height: 25px;
  width: 100%;
  outline: none;
  font-size: 10px;
  border-radius: 5px;
  padding-left: 15px;
  border: 1px solid #ccc;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
  position: relative;
}

.upload-input-box:valid {
  border-color: #8c00ff;
  color: #8400ff;
  box-shadow: 2px 2px 8px 1px #8400ff;
}

.upload-input-box:invalid {
  border-color: #ff0000;
  color: #ff0000;
  box-shadow: 2px 2px 8px 1px #ff0000;
}

.input {
  display: flex;
  flex-direction: column;
  width: 200px;
  background-color: #0D1117;
  justify-content: center;
  border-radius: 5px
}

.value {
  background-color: transparent;
  border: none;
  padding: 10px;
  color: white;
  display: flex;
  position: relative;
  gap: 5px;
  cursor: pointer;
  border-radius: 4px;
}

.value:not(:active):hover,
.value:focus {
  background-color: #21262C;
}

.value:focus,
.value:active {
  background-color: #1A1F24;
  outline: none;
}

.value::before {
  content: "";
  position: absolute;
  top: 5px;
  left: -10px;
  width: 5px;
  height: 80%;
  background-color: #2F81F7;
  border-radius: 5px;
  opacity: 0;
}

.value:focus::before,
.value:active::before {
  opacity: 1;
}

.value svg {
  width: 15px
}

.input:hover > :not(.value:hover) {
  transition: 300ms;
  filter: blur(1px);
  transform: scale(0.95,0.95);
}
body{
  background-color: #000000; ;
}