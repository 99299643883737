@font-face {
  font-family: 'Bioweapon';
  src: url('../../assets/BIOWEAPON.ttf');
  format: 'opentype';
}

.market-bg {
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url('../../assets/PageBackgrounds/market.jpg');
}

.market-container h1 {
  font-family: 'Bioweapon';
  font-style: bold;
  font-weight: 500;
  font-size: 20px;
  color: #000000;
  text-align: center;
}

.market-container h1 span {
  color: #ffffff;
}
