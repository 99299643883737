

.input-div {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 2px solid rgb(1, 235, 252);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-shadow: 0px 0px 100px rgb(118, 1, 252) , inset 0px 0px 10px rgb(152, 4, 250),0px 0px 5px rgb(255, 255, 255);
    animation: flicker 2s linear infinite;
  }
  
  .icon {
    color: rgb(165, 7, 157);
    font-size: 2rem;
    cursor: pointer;
    animation: iconflicker 2s linear infinite;
  }
  
  .input {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer !important;
  }
  
  @keyframes flicker {
    0% {
      border: 2px solid rgb(152, 1, 252);
      box-shadow: 0px 0px 100px rgb(181, 1, 252) , inset 0px 0px 10px rgb(139, 1, 252),0px 0px 5px rgb(255, 255, 255);
    }
  
    5% {
      border: none;
      box-shadow: none;
    }
  
    10% {
      border: 2px solid rgb(152, 1, 252);
      box-shadow: 0px 0px 100px rgb(193, 1, 252) , inset 0px 0px 10px rgb(139, 1, 252),0px 0px 5px rgb(255, 255, 255);
    }
  
    25% {
      border: none;
      box-shadow: none;
    }
  
    30% {
      border: 2px solid rgb(223, 1, 252);
      box-shadow: 0px 0px 100px rgb(49, 1, 94) , inset 0px 0px 10px rgb(1, 47, 252),0px 0px 5px rgb(255, 255, 255);
    }
  
    100% {
      border: 2px solid rgb(252, 1, 210);
      box-shadow: 0px 0px 100px rgb(160, 1, 252) , inset 0px 0px 10px rgb(76, 1, 252),0px 0px 5px rgb(255, 255, 255);
    }
  }
  
  @keyframes iconflicker {
    0% {
      opacity: 1;
    }
  
    5% {
      opacity: 0.2;
    }
  
    10% {
      opacity: 1;
    }
  
    25% {
      opacity: 0.2;
    }
  
    30% {
      opacity: 1;
    }
  
    100% {
      opacity: 1;
    }
  }
  
  
  button {
    font-family: inherit;
    font-size: 20px;
    background: #212121;
    color: white;
    fill: rgb(155, 153, 153);
    padding: 0.7em 1em;
    padding-left: 0.9em;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none;
    border-radius: 15px;
    font-weight: 1000;
  }
  
  button span {
    display: block;
    margin-left: 0.3em;
    transition: all 0.3s ease-in-out;
  }
  
  button svg {
    display: block;
    transform-origin: center center;
    transition: transform 0.3s ease-in-out;
  }
  
  button:hover {
    background: #000;
  }
  
  button:hover .svg-wrapper {
    transform: scale(1.25);
    transition: 0.5s linear;
  }
  
  button:hover svg {
    transform: translateX(1.2em) scale(1.1);
    fill: #fff;
  }
  
  button:hover span {
    opacity: 0;
    transition: 0.5s linear;
  }
  
  button:active {
    transform: scale(0.95);
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  