.passresetcontainer {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.passresetform_container {
  width: 400px;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.passresetinput {
  outline: none;
  border: none;
  width: 370px;
  padding: 15px;
  border-radius: 10px;
  background-color: #edf5f3;
  margin: 5px 0;
  font-size: 14px;
}

.passresetpurple_btn {
  border: none;
  outline: none;
  padding: 12px 0;
  background-color: #501c81;
  color: white;
  border-radius: 20px;
  width: 180px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
  margin: 10px auto;
}

.passreseterror_msg,
.passresetsuccess_msg {
  width: 370px;
  padding: 15px;
  margin: 5px 0;
  font-size: 14px;
  background-color: #3429cf;
  color: white;
  border-radius: 5px;
  text-align: center;
}

.passresetsuccess_msg {
  background-color: #5cdd6d;
}
